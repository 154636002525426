#MaskPage {
  opacity: 0;
  // 탭 네비게이션
  #maskNavList {
    display: none;
    
    li {
      width: calc(100% / 4);
    }
  }
}