/* font cdn */
@import url('https://fonts.googleapis.com/css?family=Mukta:400,500,600,700,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC:100,300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:100,300,400,500,700,900&display=swap&subset=korean');
/* 리셋 css */
*{margin:0;padding:0;border:0;}
html, body {width:100%; font-size: calc( 12px + 0.4vw ); font-family:'Noto Sans KR', sans-serif;}
html, h1, h2, h3, h4, h5, h6, form, fieldset, img {margin:0;padding:0;border:0}
h1, h2, h3, h4, h5, h6 {font-family:'Noto Sans KR', sans-serif;}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display:block}

ul, dl,dt,dd {margin:0;padding:0;list-style:none}
legend {position:absolute;margin:0;padding:0;font-size:0;line-height:0;text-indent:-9999em;overflow:hidden}
label, input, button, select, img {vertical-align:middle;font-size:1em}
input, button {margin:0;padding:0;font-family:'Noto Sans KR', sans-serif;;font-size:1em}
input[type="submit"]{cursor:pointer}
button {cursor:pointer}

textarea, select {font-family:'Noto Sans KR', sans-serif;;font-size:1em}
select {margin:0}
p {margin:0;padding:0;word-break:break-all}
hr {display:none}
pre {overflow-x:scroll;font-size:1.1em}
a {color:#000;text-decoration:none}
a:hover {text-decoration:none}

*, :after, :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/* 클래스 */
.avenir_bold {
  font-family: 'Mukta', sans-serif;
  font-weight: 800;
}
.avenir_heavy {
  font-family: 'Mukta', sans-serif;
  font-weight: 700;
}
.avenir_medium {
  font-family: 'Mukta', sans-serif;
  font-weight: 600;
}
.avenir_light {
  font-family: 'Mukta', sans-serif;
  font-weight: 500;
}
.clear:after {
  clear: both;
  content: " ";
  display: block;
}
.sectionTitles {
  font-size: 2.248rem;
  font-weight: 400;
  position: relative;
  padding-top: 15px;
  margin-bottom: .5rem;
}
.sectionSubTitles {
  font-size: 1.54rem;
  font-weight: 400;
}
.subSections {
  text-align: center;
  .container {
    padding-top: 6.3065%;
    padding-bottom: calc(6.3065% + 20px);
    .subTitArea {
      margin-bottom: 9.01%;
      .sectionSubTitles {
        font-weight: 500;
        color:#ffcc00;
      }
    }
  }
}
// 네비게이션
#navWrapper {
  #mainNavs {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height:75px;
    line-height: 75px;
    z-index:10000;
    background: #fff;
    border-bottom: 1px solid rgba(136, 136, 136, 0.2);
    .container {
      position: relative;
    }
    #logos {
      float:left;
      height:75px;  
      img {
        margin-top: -8px;
      }
    }
    #navList {
      float:right;
      margin-bottom: 0;
      li {
        float:left;
        padding-left:30px;
        padding-right:30px;
        .navActive {
          color:#ffcc00;
        }
        a {
          font-size: 0.83rem;
          color:#373737;
          display: block;
          width: 100%;
          font-weight: 400;
          transition: 0.5s;
          &:hover {
            color:#ffcc00;
          }
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
    #mNavBtnWrapper {
      display: none;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      button {
        display: block;
        width: 100%;
        height: 100%;
        line-height: 1;
        position: relative;
        background-color: transparent;
        .mBtnLines {
          width: calc(100% - 10px);
          height: 3px;
          background-color:rgba(136, 136, 136, 1);
          display: block;
          border-radius: 3px;
          left: 5px;
          transition: 0.5s;
        }
        #btnLine1 {
          position: absolute;
          top: 9px;
        }
        #btnLine2 {
          position: absolute;
          top: calc(50% - 1px);
        }
        #btnLine3 {
          position: absolute;
          bottom: 8px;
        }
      }
      .mNavActive {
        #btnLine1 {
          transform: translate3d(0,10px,0) rotate(45deg) ;
        }
        #btnLine2 {
          opacity: 0;
        }
        #btnLine3 {
          transform: translate3d(0,-10px,0) rotate(-45deg) ;
        }
      }
    }
  }
  #mobileNavs {
    @media(min-width:768px) {
      display: none;
    }
    #mobileNav {
      background-color: #fff;
      display: none;
      border-bottom: 1px solid rgba(136, 136, 136, 0.2);
      #mNavList {
        li {
          a {
            color:#000;
            display: block;
          }
          .navActive {
            color:#ffcc00;
          }
        }
      }
    }
  }
}
//라우트
#Route {
  margin-top: 75px;
}
//페이지헤더
.pageHeader {
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 7.3%;
  padding-bottom: 7.3%;
  color:#fff;
  @media(max-width:992px){
    padding-top: 13%;
    padding-bottom: 13%;
  }
  @media(max-width:576px){
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .sectionSubTitles {
    margin-bottom: 0;
    font-weight: 600;
  }
}
// 탭 네비게이션
.tabNavWrapper {
  width:100%;
  background-color: #fff;
  z-index: 100;
  transition: 0.5s;
  @media(max-width:576px) {
    .container {
      padding: 0;
    }
  }
  .subNav {
    text-align: center;
    border: 1px solid #ccc;
    margin-bottom: 0;
    li {
      float:left;
      height: 50px;
      line-height: 50px;
      border-right: 1px solid #ccc;
      @media(max-width:992px) {
        height:40px;
        line-height: 40px;
      }
      &:last-child {
        border-right: 0;
      }
      a {
        color:#686868;
        display:block;
        width: 100%;
        font-size: 0.8rem;
        transition: 0.5s;
        font-weight: 600;
      }
      .active {
        background-color: #ffcc00;
        color:#fff;
      }
    }
  }
}
// 풋터
.footers {
  padding-top: 2.6%;
  padding-bottom: 2.6%;
  background:#686868;
  color:#fff;
  font-size: 0.7rem;
  font-weight: 400;
  .footerHead {
    border-bottom: 2px solid #fff;
    padding-bottom: 10px;
    a {
      img {
        margin-bottom: 8px;
      }
    }
    span {
      font-weight: 600;
      line-height: 1.8;
      margin-left: 2%;
    }
  }
  .footerBody {
    .copys {
      margin: 10px auto;
      span {
        font-weight: 500;
      }
    }
    .infos {
      p {
        margin-bottom: 0;
        .infoCate {
          margin-left: 10px;
          margin-right: 5px;
          font-weight: 600;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
@media (max-width: 1200px){
  // 네비게이션
  #navWrapper {
    #mainNavs {
      height: 60px;
      line-height: 60px;
      #logos {
        height: 60px;
        img {
          max-width: 150px;
          margin-top: -5px;
        }
      }
      #navList {
        li {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
  }
  //라우트
  #Route {
    margin-top: 60px;
  }
}
@media (max-width: 992px){
  // 네비게이션
  #navWrapper {
    #mainNavs {
      #navList {
        li {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
  // 풋터
  .footers {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (max-width: 768px){
  // 네비게이션
  #navWrapper {
    #mainNavs {
      #navList {
        display: none;
      }
      #mNavBtnWrapper {
        display: block;
      }
    }
  }
}
@media (max-width: 576px){

}