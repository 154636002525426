#mainPage {
  opacity:0;
  .subTitArea {
    text-align: center;
    margin-bottom: 9.01%;
    .sectionSubTitles {
      font-weight: 500;
      color:#ffcc00;
    }
  }
  // 메인배너
  #mainBanner {
    background-image: url("http://printis.cdn3.cafe24.com/main/MainBanner.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    // &::after {
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   top: 0;
    //   left: 0;
    //   background: rgba(0,0,0,0.3);
    //   display: block;
    //   content:"";
    // }
    .sectionTitles::after {
      position: absolute;
      display: block;
      content:" ";
      width: 75px;
      height: 4px;
      background:#ffcc00;
      left: 2px;
      top: 0;
    }
    #bannerTitle {
      color:#fff;
      padding-top: 14%;
      padding-bottom: 6%;
      position: relative;
      z-index: 1;
      @media(max-width:768px) {
        padding-top: 30%;
        padding-bottom: 25%;
      }
      .sectionTitles {
        text-align: left;
      }
      .sectionSubTitles {
        text-align: left;
        margin-bottom: 1rem;
      }
    }
  }
  // 뉴스 최근글리스트
  #newsLatest {
    padding-top: 6.7%;
    padding-bottom: 7%;
    @media(max-width:768px) {
      padding-top: 50px;
      padding-bottom: 60px;
    }
    .sectionSubTitles {
      border-bottom: 2px solid #6f6c64;
      padding-bottom: 4%;
      margin-bottom: 3%;
      line-height: 0.8;
      font-weight: 500;
      a {
        color:#ffcc00;
      }
    }
    img {
      width: 100%;
    }
    #newsListContents {
      #newsThumbs {
        float:left;
        width: 40%;
        @media (max-width: 992px){
          width: 45%;
        }
        @media (max-width:768px) {
          display: none;
        }
      }
      .newsLists {
        float:right;
        width:55%;
        @media (max-width: 992px){
          width: 50%; 
        }
        @media (max-width:768px) {
          width: 100%;
        }
        .newsItems {
          margin-bottom: 15px;
          position: relative;
          line-height: 1;
          &:last-child {
            margin-bottom: 0;
          }
          a {
            font-size: 0.858rem;
            color:#686868;
            font-weight: 500;
            position: relative;
            &:hover {
              color:#686868;
            }
            .newList {
              position: absolute;
              left: 100%;
              top: 50%;
              transform: translateY(-50%);
              margin-left: 7px;
              display: block;
              img {
                width: 20px;
                display: block;
              }
            }
          }
          .listDateTime {
            position: absolute;
            right: 0;
            font-size: 0.7rem;
            bottom: 0;
          }
        }
      }
    }
  }
  // 인쇄장비
  #prints {
    padding-top: 3.76%;
    padding-bottom: 4.28%;
    .sectionSubTitles {
      a {
        color:#ffcc00
      }
    }
    #printList {
      li {
        float:left;
        width:calc(100% / 4);
        overflow: hidden;
        border-right: 1px solid rgba(136,136,136,0.2);
        height: 514px;
        position: relative;
        padding:30px 40px;
        transition: 0.5s;
        @media(max-width:1200px) {
          height: 430px;
          padding: 20px 30px;
        }
        @media (max-width: 992px){
          width: 50%; 
          height: 360px;
          &:first-child {
            border-bottom: 1px solid rgba(136,136,136,0.2);
          }
          &:nth-child(2) {
            border-bottom: 1px solid rgba(136,136,136,0.2);
            border-right: 0;
          }
          &:last-child {
            border-right: 0;
          }
        }
        .productTitle {
          h2 {
            font-size: 1.3rem;
            font-weight: 500;
            color:#686868
          }
        }
        .fadeInUp {
          height: 100%;
          a {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .printThumbs {
          position: absolute;
          bottom: 30px;
          transition: 0.5s;
          @media (max-width: 992px){
            bottom: 0;
          }
        }
        #print_0 {
          right: -40%;
          img {
            width: 227px;
          }
          @media(max-width:1200px) {
            right: -42%;
            img {
              width: 150px;
            }
          }
          @media (max-width: 992px){
            right: 0;
          }
        }
        #print_1 {
          right: -75%;
          img {
            width: 424px;
          }
          @media(max-width:1200px) {
            right: -90%;
            img {
              width: 330px;
            }
          }
          @media (max-width: 992px){
            right: -30%;
          }
        }
        #print_2 {
          right: -32%;
          img {
            width: 227px;
          }
          @media(max-width:1200px) {
            right: -35%;
            img {
              width: 180px;
            }
          }
          @media (max-width: 992px){
            right: -5%;
          }
        }
        #print_3 {
          right: -35%;
          img {
            width: 244px;
          }
          @media(max-width:1200px) {
            right: -45%;
            img {
              width: 200px;
            }
          }
          @media (max-width: 992px){
            right: -10%;
          }
        }
      }
      #printlists_0:hover {
        .printThumbs {
          right: 7%;
          @media(max-width:1200px) {
            right: 18%;
          }
        }
      }
      #printlists_1:hover {
        .printThumbs {
          right:-20%;
          @media(max-width:1200px) {
            right: -12%;
          }
        }
      }
      #printlists_2:hover {
        .printThumbs {
          right:10%;
          @media(max-width:1200px) {
            right: 9%;
          }
        }
      }
      #printlists_3:hover {
        .printThumbs {
          right:10%;
        }
      }
      .active {
        width:35%;
        box-shadow: 5px 5px 14px rgba(0,0,0,0.3);
        background:#ffcc00;
        transition: 0.6s;
        .productTitle {
          h2 {
            color:#fff;
          }
        }
        @media (max-width: 992px){
          width: 50%;
        }
      }
      .native {
        width:calc((100% - 35%) / 3);
        @media (max-width: 992px){
          width: 50%; 
        }
      }
    }
  }
  // 자동화장비
  #autos {
    padding-top: 3.76%;
    padding-bottom: 4.28%;
    .sectionSubTitles {
      a {
        color:#ffcc00
      }
    }
    #autoList {
      text-align: center;
      li {
        float:left;
        width:calc(100% / 3);
        overflow: hidden;
        border-right: 1px solid rgba(136,136,136,0.2);
        height: 514px;
        position: relative;
        padding:30px 40px;
        transition: 0.5s;
        @media(max-width:1200px) {
          height: 430px;
          padding: 20px 30px;
        }
        @media (max-width: 992px){
          height: 360px;
        }
        @media (max-width:768px) {
          width: calc(100% / 2);
          border-bottom: 1px solid rgba(136,136,136,0.2);
          &:nth-child(2) {
            border-right: 0;
          }
          &:last-child {
            width: 100%;
            border-right: 0;
            border-bottom: 0;
          }
        }
        .fadeInUp {
          height: 100%;
          a {
            display: block;
            width: 100%;
            height:100%;
          }
        }
        .productTitle {
          text-align: left;
          h2 {
            font-size: 1.3rem;
            font-weight: 500;
            color:#686868
          }
        }
        .sectionSubTitles {
          font-weight: 500;
          color:#686868;
        }
        .autoThumbs {
          position: absolute;
          bottom: 30px;
          transition: 0.5s;
          @media(max-width: 768px) {
            bottom: 0;
          } 
        }
        #auto_0 {
          right: -30%;
          img {
            width: 316px;
          }
          @media(max-width:1200px) {
            right: -42%;
            img {
              width: 250px;
            }
          }    
          @media (max-width: 992px){
            img {
              width: 200px;
            }
          }
        }
        #auto_1 {
          right: -15%;
          img {
            width: 226px;
          }
          @media (max-width: 992px){
            right: -30%;
            img {
              width: 180px;
            }
          }
        }
        #auto_2 {
          right: -44%;
          img {
            width: 390px;
          }
          @media(max-width:1200px) {
            right: -42%;
            img {
              width: 300px;
            }
          }
          @media (max-width: 768px) {
            right: 10%;
          }
        }
      }
      #autolists_0:hover {
        .autoThumbs {
          right: 5%;
        }
      }
      #autolists_1:hover {
        .autoThumbs {
          right: 20%;
          @media (max-width: 992px){
            right: 10%;
          }
        }
      }
      #autolists_2:hover {
        .autoThumbs {
          right: 3%;
          @media (max-width: 992px){
            right: -3%;
          }
        }
      }
      .active {
        width:45%;
        box-shadow: 5px 5px 14px rgba(0,0,0,0.3);
        background:#ffcc00;
        transition: 0.6s;
        .productTitle {
          h2 {
            color:#fff;
          }
        }
        @media(max-width: 768px) {
          width:50%;
        }
      }
      .native {
        width:calc((100% - 45%) / 2);
        @media(max-width: 768px) {
          width:50%;
        }
      }
    }
  }
  // 파트너
  #partners {
    padding-top: 6.3%;
    padding-bottom: 6.3%;
    background:#ccc;
    text-align: center;
    .swiper-container {
      img {
        max-width: 80%;
      }
      .swiper-button-next {
        color:#fff;
        right: 0;
        &:after {
          font-size: 1rem;
        }
      }
      .swiper-button-prev {
        color:#fff;
        left: 0;
        &:after {
          font-size: 1rem;
        }
      }
    }
  }
  // 연락처
  #contact {
    padding-top: 5.5%;
    padding-bottom: 5.5%;
    color:#686868;
    font-weight: 500;
    #contactInfo {
      margin-bottom: 0;
      li {
        float: left;
        width:calc(100% / 3);
        &:first-child {
          .sectionSubTitles {
            color:#ffcc00
          }
        }
        &:last-child {
          text-align: right;
          div {
            display: inline-block;
            text-align: left;
          }
        }
        @media (max-width: 992px){
          &:first-child{
            width:30%;
          }
          &:nth-child(2) {
            width: 40%;
            .addressLine {
              display: block;
              #infoFax {
                margin-left: 0;
              }
            }
          }
          &:last-child{
            width:30%;
            p {
              &:first-child {
                br {
                  display: none;
                }
              }
            }
          }
        }
        @media(max-width:768px){
          &:first-child {
            width: 40%;
          }
          &:nth-child(2) {
            width: 60%;
            float: right;
          }
          &:last-child {
            margin-top: 10px;
            float: right;
            width: 60%;
            text-align: left;
          }
        }
        .sectionSubTitles {
          font-weight: 600;
        }
        p {
          margin-bottom: 0;
          font-size: 0.8rem;
          #infoFax {
            margin-left: 10px;
          }
        }
      }
    }
  }
  // 오시는길
  #locations {
    .mapArea {
      height: 600px;
      @media(max-width: 992px) {
        height: 400px;
      }
    }
  }
}